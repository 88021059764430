<template>
	<b-card title="Inspiration List">
		<b-row class="mb-3">
			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="type">Type:</label>
					<v-select
						id="type"
						v-model="filter.content_type"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="title"
						placeholder="Pilih Type"
						:options="type"
						:reduce="type => type.value"
					/>;
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="category">Category:</label>
					<v-select
						id="category"
						v-model="filter.nameCategory"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="content_category_name"
						placeholder="Pilih Category"
						:options="category"
						@input="inputSubCategory($event.content_category_slug)"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="subCategory">Sub Category:</label>
					<v-select
						id="subCategory"
						v-model="filter.sub_category_name"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="content_subcategory_name"
						placeholder="Pilih Sub Category"
						:options="subCategory"
						:reduce="subCategory => subCategory.content_subcategory_name"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="status">Status:</label>
					<v-select
						id="status"
						v-model="filter.status"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="title"
						placeholder="Pilih Status"
						:options="status"
						:reduce="status => status.value"
					/>
				</div>
			</b-col>
			
			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>Start Date:</label>
					<flat-pickr
						v-model="filter.start_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							maxDate: new Date(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>End Date:</label>
					<flat-pickr
						v-model="filter.end_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							maxDate: new Date(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="keyword">Keyword:</label>
					<input 
						id="keyword"
						type="text" 
						class="form-control"
						placeholder="Search: Title"
						v-model="filter.keyword" 
					>
				</div>
			</b-col>
			
			<b-col cols="12" class="mt-2">
				<button
					class="btn btn-outline-secondary mr-2"
					@click="clearFilter()" 
				>
					Reset
				</button>
				<button
					class="btn btn-primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export
					@click="getDataExport"
				>
					Export
				</button>
				
			</b-col>
			<Export
				:is-loading="isLoading"
				:result-export="resultExport"
				:export-now="exportNow"
			/>
		</b-row>
		
		<Table 
			:result="result"
			:inspiration="inspiration"
			:is-loading="isLoading"
			:get-data="getData"
			:draft-item="draftItem"
			:delete-item="deleteItem"
			:current-page="currentPage"
			:pin-item-content="pinItemContent"
			@filter="getFilter"
		/>
	</b-card>
</template>

<script>
import Export from '@/components/Export.vue'
import Table from '@/components/inspiration/Table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			isLoading: false,
			result: null,
			inspiration: null,
			link:'',
			isLoadingExport: false,
			filter: {
				content_type: '',
				nameCategory: '',
				sub_category_name: '',
				status: '',
				start_date: '',
				end_date: '',
				keyword: '',
				order_type: ''
			},
			type: [
				{ 
					title: 'Artikel',
					value: 'article'
				}, 
				{ 
					title: 'Video',
					value: 'video'
				}, 
				{ 
					title: 'Status',
					value: 'text_only'
				}
			],
			status: [
				{ 
					title: 'Draft',
					value: 'draft'
				}, 
				{ 
					title: 'Publish',
					value: 'publish'
				}, 
			],
			resultExport: [],
			category: [],
			subCategory:[],
			sort: '',
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.filter.category_name = this.filter.nameCategory ? this.filter.nameCategory.content_category_name : ''
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	created() {
		this.getData()
	},
	mounted() {
		this.categoryName()
	},
	methods: {
		getFilter(value) {
			this.filter.order_type = value.order_type
		},
		submitFilter() {
			this.getData(this.currentPage)
		},
		inputSubCategory(slug) {
			this.$http.get(`/admin/sub-category-contents?content_category_slug=${slug}`)
			.then(response => {
				this.subCategory = response.data.data
				// this.filter.sub_category_name = ''
			})
		},
		categoryName() {
			this.$http.get('/admin/category-contents')
			.then(response => {
				this.category = response.data.data
			})
		},
		clearFilter() {
			this.filter = {
				content_type: '',
				nameCategory: '',
				category_name:'',
				sub_category_name: '',
				status: '',
				start_date: '',
				end_date: '',
				keyword: '',
				order_type: ''
			}
		},
		getDataExport() {
			this.isLoadingExport = true
			this.$http.get('/admin/export/inspiration/list'
			).then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
			})
		},
		exportNow() {
			this.isLoadingExport = true
			const exportParam = this.filter
			exportParam.per_page = null
			this.$http.post('/admin/export/inspiration/export', exportParam).then(response => {
				if(response) {
					this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					})
					this.$bvModal.hide('form-modal-export')
					this.isLoadingExport = false
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validatiosns
				}
			})
		},
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page
			this.$http.get('/admin/inspirations', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.inspiration = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})	
		},
		draftItem(slug) {
			this.$swal({
				title: 'Are you sure?',
				text: 'Are you sure to disable this inspiration?',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
				this.isLoading = true
				this.$http.post(`/admin/inspirations/set-draft/${slug}`)
					.then(response => {
					this.getData(this.currentPage)
					this.$swal({
						icon: 'success',
						title: 'Success!',
						text: 'Inspiration berhasil draft.',
						customClass: {
						confirmButton: 'btn btn-success',
						},
					})
					this.isLoading = false
					})
					.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					})
				}
			})
		},
		deleteItem(slug) {
			this.$swal({
				title: 'Are you sure?',
				text: 'Are you sure to delete this inspiration?',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
				this.isLoading = true
				this.$http.delete(`/admin/inspirations/${slug}`)
					.then(response => {
					this.getData(this.currentPage)
					this.$swal({
						icon: 'success',
						title: 'Success!',
						text: 'Inspiration successfully deleted',
						customClass: {
						confirmButton: 'btn btn-success',
						},
					})
					this.isLoading = false
					})
					.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					})
				}
			})
		},
		pinItemContent(slug, unpined = false) {
			this.$swal({
				title: 'Are you sure?',
				text: (unpined) ? 'Are you sure to Unpinned this inspiration?' :  'Are you sure to pinned this inspiration?',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
				this.isLoading = true
				var data = { 
					is_unpinned : unpined
				}
				this.$http.post(`/admin/inspirations/set-pin-content/${slug}`, data)
					.then(response => {
					this.getData(this.currentPage)
					this.$swal({
						icon: 'success',
						title: 'Success!',
						text: (unpined) ? 'Inspiration successfully unpinned' : 'Inspiration successfully pinned',
						customClass: {
						confirmButton: 'btn btn-success',
						},
					})
					this.isLoading = false
					})
					.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					})
				}
			})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>